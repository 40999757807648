import React from "react"
import styled from "@emotion/styled"

const YouTubeContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  margin-bottom: 20px;
  height: 0;
  overflow: hidden;
  box-shadow: 3px -10px 50px rgba(0,0,0, .4);

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`

export default function YouTubeVideo(props) {
  const { videoId } = props

  return (
    <YouTubeContainer>
      <img src={`https://img.youtube.com/vi/${videoId}/sddefault.jpg`} alt="" />
      <iframe
        title="YouTube Video"
        width="560"
        height="315"
        src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="allowfullscreen"
      />
    </YouTubeContainer>
  )
}
