import React from "react"


export default function LargeSection(props) {
  const { className } = props // image, flip, regSpace, style 

  return (
    <section className={className}>
        <div className="container ct">
          {props.children}
        </div>
    </section>
   )
}
