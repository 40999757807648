import React from "react"
import { PostPreview } from "./ArticleList"

export default function NextAndPrevPostsNav({ next, prev }) {
  const nextPost = next && next.node
  const prevPost = prev && prev.node

  return (
    <div className="container">
      <h2 className="h1 giant primary-color">More?</h2>
      <div className="moreArticles">
        {[prevPost, nextPost].map((post, index) => {
          if (post) {
            return <PostPreview post={post} key={post.fields.slug} />

            // return (
            //     <article className="moreArticles__article" key={post.fields.slug} data-sal="slide-up">

            //         {post.frontmatter.image ? (
            //             <div className="moreArticles__image">
            //             <Link to={post.fields.slug}>
            //                 <Img fluid={post.frontmatter.image.childImageSharp.fluid} alt={post.frontmatter.title} />
            //             </Link>
            //             </div>
            //         ) : null}

            //         <div className="moreArticles__content">
            //         <Link to={post.fields.slug}><h3>{post.frontmatter.title}</h3></Link>
            //             <PostMeta post={post} />
            //             <Button to={post.fields.slug}>Read more</Button>
            //         </div>
            //     </article>
            // )
          } else {
            if (index === 0) {
              return (
                <article className="moreArticles__article moreArticles__article--no-more">
                  <div className="moreArticles__wrapper">
                    <div className="moreArticles__content">
                      <div className="h2">
                        <span role="img" aria-label="No previous articles">
                          🤗
                        </span>
                        <span>No previous articles</span>
                      </div>
                    </div>
                  </div>
                </article>
              )
            }
            return (
              <article className="moreArticles__article moreArticles__article--no-more">
                <div className="moreArticles__wrapper">
                  <div className="moreArticles__content">
                    <div className="h2">
                      <span role="img" aria-label="No further articles">
                        🤗
                      </span>
                      <span>No further articles</span>
                    </div>
                  </div>
                </div>
              </article>
            )
          }
        })}
      </div>
    </div>
  )
}

/*
{nextPost.frontmatter.image ? (
                    <Link to={nextPost.fields.slug}>
                        <Img fluid={nextPost.frontmatter.image.childImageSharp.fluid} alt={nextPost.frontmatter.title} />
                    </Link>
                ) : null}
*/

/*
<Link to={nextPost.fields.slug}><h2>{nextPost.frontmatter.title}</h2></Link>
                    <PostMeta post={nextPost} />
                    <p className="articleList__excerpt">{nextPost.frontmatter.description || nextPost.excerpt}</p>
                    <Button to={nextPost.fields.slug}>Read more</Button>
*/
