import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import slugify from "slugify"

import { Tag } from "../assets/icons"

const PostMetaStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  //margin-bottom: 40px;

  .date {
  }

  .timeToRead {
    &:before {
      content: " - ";
    }
  }

  .categories {
    width: 100%;
    padding: 0;
    margin: 0;
    color: var(--primary-color);
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 0 10px;
      text-transform: uppercase;
    }
  }
`

export default function PostMeta({ post }) {
  //title, image,
  const { date, category } = post.frontmatter
  const { timeToRead } = post

  return (
    <PostMetaStyles>
      <div className="dateAndTime">
        <span className="date">{date}</span>
        {timeToRead ? (
          <span className="timeToRead">
            <span role="img" aria-label="Coffee emoji">
              ☕️
            </span>{" "}
            {timeToRead} min. read
          </span>
        ) : null}
      </div>
      <ul className="categories">
        {category.map(c => (
          <li key={slugify(c, { lower: true })}>
            <Link to={`/category/${slugify(c, { lower: true })}`}>
              <Tag /> {c}
            </Link>
          </li>
        ))}
      </ul>
    </PostMetaStyles>
  )
}
