import React from "react"
import { Link } from "gatsby"

export default function Button(props) {
  return (
    <Link to={props.to} className="button">
      {props.children}
    </Link>
  )
}
