import React from "react"
import Img from "gatsby-image"
import { Link } from 'gatsby'
import Button from "./Button"
import PostMeta from './PostMeta'


export default function ArticleList({ posts }) {

  return (
    <div className="articleList">
        {posts.map(post => <PostPreview post={post} /> )}
    </div>
  )
}


export function PostPreview ( props ){

  const {post} = props;
  const { image, title } = post.frontmatter;

  console.log(post)

  return (
    <article className="articleList__article">
      <div className="articleList__articleWrapper">
      <div className="articleList__image">
        {image ? (<Link to={post.fields.slug}><Img fluid={image.childImageSharp.fluid} alt={title} /></Link>) : null}
      </div>
      <div className="articleList__content">
        <Link to={post.fields.slug}><h2>{post.frontmatter.title}</h2></Link>
        <PostMeta post={post} />
        <p className="articleList__excerpt">{post.frontmatter.description || post.excerpt}</p>
        <Button to={post.fields.slug}>Read more</Button>
      </div>
      </div>
    </article>
  )
}