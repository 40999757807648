import React from 'react'
import { Helmet } from 'react-helmet';

export function PostMetaTags({ post }) {

    const baseUrl = 'https://thomaszipner.com'
    const fullUrl = `${baseUrl}/${post.slug}`
    const canonical = fullUrl

    console.log(post);
    const thumbnailData = {
        title: post.frontmatter.title,
        fullUrl,
        thumbnail: post.frontmatter.image?.publicURL,
      };
    console.log("thumbnailData", thumbnailData)
    /*const thumbnailQuery = new URLSearchParams(
        Object.fromEntries(
          Object.entries(thumbnailData).filter(([key, val]) => val !== undefined)
        )
      ).toString();*/

      const ogImage = `nonono`;

    return (
    <Helmet>
      <link rel="canonical" href={canonical} />
      <meta name="generator" content="Thomas Zipner" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@ThomasZipner" />
      <meta name="twitter:creator" content="@ThomasZipner" />
      <meta name="twitter:url" content={fullUrl} />
      <meta name="twitter:title" content={post.frontmatter.title} />
      <meta name="twitter:description" content={post.excerpt} />
      <meta name="twitter:image" content={ogImage} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={post.frontmatter.title} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:description" content={post.excerpt} />
      {post.frontmatter.date ? (
        <meta
          property="article:published_time"
          content={new Date(post.frontmatter.date).toISOString()}
        />
      ) : null}

      <meta property="og:site_name" content="Thomas Zipner" />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:locale" content="de_DE" />
      <title>{post.frontmatter.title} - Thomas Zipner</title>
    </Helmet>
    )
}
