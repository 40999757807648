import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { Disqus } from "gatsby-plugin-disqus"

import SEO from "../components/seo"
import YouTubeVideo from "../components/YouTubeVideo"
import { PostMetaTags } from "../components/MetaTags"
import PostMeta from "../components/PostMeta"
import NextAndPrevPostsNav from "../components/NextAndPrevPostsNav"
import LargeSection from "../components/LargeSection"
import Separator from "../components/Separator"
/*
site {
      siteMetadata {
        title
        author
      }
    }

*/

function TLDR(props) {
  return (
    <div>
      <h1>TLDRRRR</h1>props.content
    </div>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      slug
      excerpt
      timeToRead
      fileAbsolutePath
      frontmatter {
        title
        slug
        date(formatString: "MMMM DD, YYYY")
        image {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        category
      }
      body
    }
  }
`

const PostWrapper = styled.article`
  position: relative;
  z-index: 1;
  margin: 40vh auto 20px auto;

  @media only screen and (min-width: 960px) {
    margin: 50vh auto 20px auto;
  }

  width: 100%;
  max-width: 960px;

  padding: 20px 20px;

  img {
    width: auto;
    max-width: 100%;
  }

  h1 {
    margin-bottom: 0;
  }

  .gatsby-image-wrapper {
    //box-shadow: 1px 1px 20px rgba(0,0,0,.5);
  }
`

const CommentSection = styled.article`
  margin: 40px 0;
  padding-top: 40px;
`

function Cola() {
  return <h1>Cola</h1>
}

function PostTemplate({
  data: { mdx: post },
  scope,
  pageContext,
  siteMetadata,
}) {
  const { title, image } = post.frontmatter
  const { next, prev, slug, id } = pageContext

  // window does not exist at build time
  //const pageUrl = `${window.location.origin}${slug}`

  if (!post) {
    return <p>No Post Found? This should be a 404</p>
  }

  const shortcodes = { YouTubeVideo, TLDR, Cola }
  console.log("POST", post, scope)
  //  {post.frontmatter.image ? (<Img image={post.frontmatter.image} alt={post.frontmatter.title} />) : null}
  return (
    <>
      <SEO title={title} description={post.excerpt} article={true} />

      <PostMetaTags post={post} />
      <div className="featuredImage">
        {image ? (
          <Img
            fluid={image.childImageSharp.fluid}
            alt={title}
            style={{ maxWidth: "1920px", maxHeight: "110vh" }}
            className="featuredImage__image"
            loading="eager"
          />
        ) : null}
      </div>
      <PostWrapper>
        <h1>{title}</h1>
        <PostMeta post={post} />

        <MDXRenderer
          scope={{
            ...shortcodes,
            ...scope,
          }}
        >
          {post.body}
        </MDXRenderer>
        {/* <CommentSection>

      <hr />
        <h2 class="h1 giant primary-color">Any thoughts?</h2>
      <Disqus
        config={{
            url: pageUrl,
            identifier: id,
            title: title,
        }
        }
    />

  </CommentSection> */}
      </PostWrapper>

      <LargeSection className="diagonal-section">
        <NextAndPrevPostsNav next={next} prev={prev} />
      </LargeSection>
    </>
  )
}

export default PostTemplate
